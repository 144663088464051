import { useCallback, useEffect, useState } from 'react'
import { Card, Text, Tooltip, Icon } from '@shopify/polaris'
import { ReportMinor } from '@shopify/polaris-icons'
import { useAppBridge } from '@shopify/app-bridge-react'
import { DataSeries, SparkLineChart } from '@shopify/polaris-viz'

import { appRedirect } from '../../lib/redirect'

import './index.css'

export type Trend = 'neutral' | 'positive' | 'negative'

interface IProps {
    id: number
    title: string
    description: string
    kpi_range: string
    currentResult: number | null
    data: DataSeries[]
    percentageChange: number
    kpi?: number
    numericTrend: Trend
    evaluatedTrend: Trend
}

const CardMonitor: React.FC<IProps> = (props: IProps): any => {
    const [redirectId, setRedirectId] = useState<number | null>(null)
    const [currentResult, setCurrentResult] = useState<number | null>(null)
    const app = useAppBridge()

    useEffect(() => {
        if (redirectId && !isNaN(+redirectId)) {
            appRedirect(app, '/details/' + redirectId)
        }
    }, [redirectId, app])

    useEffect(() => {
        if (props.currentResult !== undefined) {
            setCurrentResult(props.currentResult)
        }
    }, [props.currentResult])

    const triggerRedirect = useCallback(() => {
        setRedirectId(props.id)
    }, [props.id])

    const renderTrendIndicator = useCallback(() => {
        let color: string = ''

        if (props.evaluatedTrend === 'neutral') {
            color = 'grey'
        } else if (props.evaluatedTrend === 'positive') {
            color = 'green'
        } else {
            color = 'red'
        }

        return (
            <span className='trendindicator-percent' style={{ color }} key={'trend-' + props.id}>
                <span>
                    {props.numericTrend === 'positive' ? (
                        <span>↗</span>
                    ) : props.numericTrend === 'neutral' ? (
                        <span>→</span>
                    ) : (
                        <span>↘</span>
                    )}
                </span>
                <span style={{ marginLeft: '2px' }}>{props.percentageChange}%</span>
            </span>
        )
    }, [props])

    return (
        <Card roundedAbove='sm'>
            <span className='details'>
                <a href='#' onClick={() => triggerRedirect()} title=''>
                    <Icon source={ReportMinor} tone='subdued' accessibilityLabel='Details' />
                </a>
            </span>
            <Tooltip content={props.description} hasUnderline preferredPosition='above'>
                <Text fontWeight='bold' as='span'>
                    {props.title}
                </Text>
            </Tooltip>

            {currentResult === null ? (
                <div style={{ height: '45px', padding: '1%' }}>
                    <span className='loader' />
                </div>
            ) : (
                <div>
                    <div className='kpi'>
                        <span>
                            {currentResult} {props.kpi}
                        </span>
                        <span>{renderTrendIndicator()}</span>
                    </div>

                    <div className='chart'>
                        <div className='chartInner'>
                            <SparkLineChart data={props.data} isAnimated theme='Light' />
                        </div>
                    </div>
                </div>
            )}
        </Card>
    )
}

export default CardMonitor
