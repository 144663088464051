export const timeFormatOptions = [
    {
        label: 'YYYY/MM/DD',
        value: 'YYYY/MM/DD'
    },
    {
        label: 'DD/MM/YYYY',
        value: 'DD/MM/YYYY'
    },
    {
        label: 'MM/DD/YYYY',
        value: 'MM/DD/YYYY'
    },
    {
        label: 'DD-MM-YYYY',
        value: 'DD-MM-YYYY'
    },
    {
        label: 'YYYY-MM-DD',
        value: 'YYYY-MM-DD'
    },
    {
        label: 'DD.MM.YYYY',
        value: 'DD.MM.YYYY'
    },
    {
        label: 'YYYY.MM.DD',
        value: 'YYYY.MM.DD'
    }
]

export const languageOptions = [
    {
        label: 'German',
        value: 'de'
    },
    {
        label: 'English',
        value: 'en'
    },
    {
        label: 'Spanish',
        value: 'es'
    },
    {
        label: 'French',
        value: 'fr'
    },
    {
        label: 'Italian',
        value: 'it'
    },
    {
        label: 'Japanese',
        value: 'ja'
    },
    {
        label: 'Korean',
        value: 'ko'
    },
    {
        label: 'Chinese (Simplified)',
        value: 'zh-CN'
    },
    {
        label: 'Russian',
        value: 'ru'
    },
    {
        label: 'Arabic',
        value: 'ar'
    },
    {
        label: 'Portuguese',
        value: 'pt'
    },
    {
        label: 'Hindi',
        value: 'hi'
    },
    {
        label: 'Bengali',
        value: 'bn'
    },
    {
        label: 'Dutch',
        value: 'nl'
    },
    {
        label: 'Swedish',
        value: 'sv'
    },
    {
        label: 'Turkish',
        value: 'tr'
    },
    {
        label: 'Polish',
        value: 'pl'
    },
    {
        label: 'Greek',
        value: 'el'
    },
    {
        label: 'Vietnamese',
        value: 'vi'
    },
    {
        label: 'Thai',
        value: 'th'
    },
    {
        label: 'Hebrew',
        value: 'he'
    },
    {
        label: 'Finnish',
        value: 'fi'
    },
    {
        label: 'Norwegian',
        value: 'no'
    },
    {
        label: 'Danish',
        value: 'da'
    },
    {
        label: 'Indonesian',
        value: 'id'
    },
    {
        label: 'Malay',
        value: 'ms'
    },
    {
        label: 'Czech',
        value: 'cs'
    },
    {
        label: 'Hungarian',
        value: 'hu'
    },
    {
        label: 'Romanian',
        value: 'ro'
    },
    {
        label: 'Slovak',
        value: 'sk'
    },
    {
        label: 'Catalan',
        value: 'ca'
    },
    {
        label: 'Tagalog',
        value: 'tl'
    },
    {
        label: 'Swahili',
        value: 'sw'
    },
    {
        label: 'Ukrainian',
        value: 'uk'
    },
    {
        label: 'Bulgarian',
        value: 'bg'
    },
    {
        label: 'Serbian',
        value: 'sr'
    }
]
