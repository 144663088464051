import axios from 'axios'
import {
    ChoiceList,
    ChoiceListProps,
    Frame,
    Card,
    Page,
    Text,
    TextField,
    Loading,
    Grid,
    Button,
    Toast,
    Checkbox,
    Select
} from '@shopify/polaris'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppBridge } from '@shopify/app-bridge-react'
import { getSessionToken } from '@shopify/app-bridge-utils'
import { useSearchParams } from 'react-router-dom'

import FooterBanner from '../../components/footer-banner'
import { ICheck, IPlan, IStore, IStoreSettings } from '../../types/supabase'
import SideNavigation from '../../components/side-navigation'
import Plans from '../../components/plans'
import { languageOptions, timeFormatOptions } from './constants'

async function fetchData(token: string): Promise<[IStore, Array<ICheck>, IStoreSettings, IPlan]> {
    const [storeRes, checkRes, settingsRes, planRes] = await Promise.all([
        axios.get<IStore>(process.env.REACT_APP_PUBLIC_HOST + '/api/sb/store', {
            headers: {
                Authorization: token
            }
        }),
        axios.get<Array<ICheck>>(process.env.REACT_APP_PUBLIC_HOST + '/api/sb/checks', {
            headers: {
                Authorization: token
            }
        }),
        axios.get<IStoreSettings>(process.env.REACT_APP_PUBLIC_HOST + '/api/sb/settings', {
            headers: {
                Authorization: token
            }
        }),
        axios.get<IPlan>(process.env.REACT_APP_PUBLIC_HOST + '/api/sb/plan', {
            headers: {
                Authorization: token
            }
        })
    ])

    return [storeRes.data, checkRes.data, settingsRes.data, planRes.data]
}

function formatChoices(data: ICheck[]): ChoiceListProps['choices'] {
    return data.map((check) => {
        return {
            id: String(check.id),
            value: String(check.id),
            label: check.title
        }
    }) as any as ChoiceListProps['choices']
}

function getIdDiff(idList: string[], filter: string[]): string[] {
    return idList.filter((id) => !filter.includes(id))
}

function Settings() {
    const { t } = useTranslation()
    const app = useAppBridge()

    const [searchParams] = useSearchParams()
    const [settings, setSettings] = useState<IStoreSettings>()
    const [choices, setChoices] = useState<ChoiceListProps['choices']>([])
    const [activated, setActivated] = useState<Array<string>>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [location, setLocation] = useState<string>(searchParams.get('location') ?? 'general')
    const [toastMessage, setToastMessage] = useState<string | undefined>()

    const [email, setEmail] = useState<string | undefined>()
    const [sendMonitorMail, setSendmonitorMail] = useState<boolean>(false)
    const [sendMarketingMail, setSendMarketingMail] = useState<boolean>(false)
    const [timeFormat, setTimeFormat] = useState<string | undefined>()
    const [primaryLocale, setPrimaryLocale] = useState<string | undefined>()

    useEffect(() => {
        getSessionToken(app)
            .then((token) => {
                fetchData(token).then(([store, checks, settings]) => {
                    setEmail(store.email)
                    setSettings(settings)
                    setTimeFormat(settings.time_format)
                    setPrimaryLocale(settings.primary_locale)
                    setEmail(settings.monitor_mail_address)
                    setSendmonitorMail(settings.monitor_mail)
                    setSendMarketingMail(settings.marketing_mail)
                    setChoices(formatChoices(checks))
                    setActivated(
                        getIdDiff(
                            checks.map((check) => String(check.id)),
                            settings?.checks_deactivated?.split(',') ?? []
                        )
                    )
                    setLoading(false)
                })
            })
            .catch((err) => {
                console.error(err)
            })
    }, [app])

    async function updateChecks(settings: IStoreSettings, selected: string[]): Promise<void> {
        const sessionToken = await getSessionToken(app)
        const deactivated = settings.checks_deactivated?.split(',') ?? []

        console.log(sessionToken, deactivated, selected)
    }

    async function updateSettings(): Promise<boolean> {
        const sessionToken = await getSessionToken(app)
        const params = new URLSearchParams()

        params.set('email', email as string)
        params.set('monitor_mail', sendMonitorMail ? '1' : '0')
        params.set('marketing_mail', sendMarketingMail ? '1' : '0')

        if (timeFormat) {
            params.set('time_format', timeFormat)
        }

        if (primaryLocale) {
            params.set('primary_locale', primaryLocale)
        }

        const res = await axios.get(process.env.REACT_APP_PUBLIC_HOST + '/api/sb/update_settings?' + params.toString(), {
            headers: {
                Authorization: sessionToken
            }
        })

        return res.data.success
    }

    return (
        <Frame>
            {loading && <Loading />}
            {toastMessage && (
                <Toast
                    content={toastMessage}
                    onDismiss={() => {
                        setToastMessage(undefined)
                    }}
                ></Toast>
            )}

            <Page title={t('settings.title')} fullWidth>
                <Grid>
                    <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 3, xl: 3 }}>
                        <SideNavigation initialNav={location} onSetNav={setLocation} />
                    </Grid.Cell>

                    <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 8, xl: 8 }}>
                        {location === 'general' && (
                            <Card>
                                <Text as='h1' variant='headingXl'>
                                    {t('settings.card.general.title')}
                                </Text>
                                <Text as='p' tone='subdued'>
                                    {t('settings.card.general.text')}
                                </Text>

                                <br />
                                <TextField
                                    value={email}
                                    onChange={setEmail}
                                    placeholder='E-Mail Address'
                                    name='email'
                                    label={t('settings.card.general.label_email')}
                                    autoComplete=''
                                    requiredIndicator
                                ></TextField>
                                <br />

                                <Select
                                    label={t('settings.card.general.label_timeformat')}
                                    onChange={setTimeFormat}
                                    value={timeFormat}
                                    options={timeFormatOptions}
                                    requiredIndicator
                                ></Select>
                                <br />

                                <Select
                                    label={t('settings.card.general.label_locale')}
                                    onChange={setPrimaryLocale}
                                    value={primaryLocale}
                                    options={languageOptions}
                                    requiredIndicator
                                ></Select>

                                <br />
                                <Button
                                    disabled={loading}
                                    onClick={() =>
                                        updateSettings().then((success) => {
                                            if (success) {
                                                setToastMessage(t('settings.settings_updated'))
                                            } else {
                                                setToastMessage(t('settings.update_error'))
                                            }
                                        })
                                    }
                                >
                                    Save
                                </Button>
                            </Card>
                        )}

                        {location === 'notifications' && (
                            <Card>
                                <Text as='h1' variant='headingXl'>
                                    {t('settings.card.notifications.title')}
                                </Text>
                                <Text as='p' tone='subdued'>
                                    {t('settings.card.notifications.text')}
                                </Text>
                                <br />
                                <Checkbox
                                    onChange={setSendmonitorMail}
                                    checked={sendMonitorMail}
                                    label={t('settings.card.notifications.label_check')}
                                />
                                <br />
                                <Checkbox
                                    onChange={setSendMarketingMail}
                                    checked={sendMarketingMail}
                                    label={t('settings.card.notifications.label_marketing')}
                                />
                                <br />
                                <br />
                                <Button
                                    disabled={loading}
                                    onClick={() =>
                                        updateSettings().then((success) => {
                                            if (success) {
                                                setToastMessage(t('settings.notifications_updated'))
                                            } else {
                                                setToastMessage(t('settings.update_error'))
                                            }
                                        })
                                    }
                                >
                                    Save
                                </Button>
                            </Card>
                        )}

                        {location === 'monitors' && (
                            <Card>
                                <Text as='h1' variant='headingXl'>
                                    {t('settings.card.checks.title')}
                                </Text>
                                <Text as='p' tone='subdued'>
                                    {t('settings.card.checks.text')}
                                </Text>
                                <br />
                                {!loading && (
                                    <ChoiceList
                                        disabled={true}
                                        title={''}
                                        selected={activated}
                                        allowMultiple
                                        choices={choices}
                                        onChange={(selected) => updateChecks(settings!, selected)}
                                    />
                                )}
                            </Card>
                        )}

                        {location === 'plans' && <Plans isOnboarding={false} />}
                    </Grid.Cell>
                </Grid>

                <div>
                    <Frame>{!loading && <FooterBanner />}</Frame>
                </div>
            </Page>
        </Frame>
    )
}

export default Settings
