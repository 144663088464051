import { I18nextProvider } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import i18next from 'i18next'

import translationDE from './translations/de/common.json'
import translationEN from './translations/en/common.json'
import App from './App'

import { createRoot } from 'react-dom/client'

const domNode = document.getElementById('root')
const root = createRoot(domNode!)

const resources = {
    en: {
        translation: translationEN
    },
    de: {
        translation: translationDE
    }
}

i18next.use(LanguageDetector).init({
    interpolation: { escapeValue: false },
    debug: false,
    resources,
    fallbackLng: 'en'
})

root.render(
    <I18nextProvider i18n={i18next}>
        <App />
    </I18nextProvider>
)
