import { ClientApplication } from '@shopify/app-bridge'
import { Redirect } from '@shopify/app-bridge/actions'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function appRedirect(app: ClientApplication<any>, path: string, payload?: Record<string, unknown>): void {
    Redirect.create(app).dispatch(Redirect.Action.APP, path)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function adminRedirect(app: ClientApplication<any>, path: string): void {
    Redirect.create(app).dispatch(Redirect.Action.ADMIN_PATH, path)
}
