import { useState, useEffect } from 'react'
import { Layout, Button, Modal, Toast, Card, Text, Divider, Grid, List, Badge } from '@shopify/polaris'
import { useAppBridge } from '@shopify/app-bridge-react'
import { getSessionToken } from '@shopify/app-bridge-utils'

import { useTranslation } from 'react-i18next'

import { IPurchaseResponse, IPurchaseVerificationResponse, IStaticPlan } from '../../types/base'
import GET_DEFAULT_ERR_MESSAGE from '../../lib/constants/error-message'
import { adminRedirect, appRedirect } from '../../lib/redirect'
import { ErrorAnimation, SuccessAnimation } from '../success-animation/index'
import { httpRequest } from '../../lib/request'
import { BASE_URL } from '../../lib/constants/env'
import { IPlan } from '../../types/supabase'
import getPlans from '../../lib/plans'

import './style.css'

interface IProps {
    isOnboarding: boolean
    isLegacyStore?: boolean
    onboardingComplete?: () => void
}

const advantageCardColSpans = {
    leftBound: { xs: 5, sm: 6, md: 6, lg: 5, xl: 5 },
    rightBound: { xs: 5, sm: 6, md: 6, lg: 7, xl: 7 }
}

const advantageCardGap = { xs: '45px', sm: '45px', md: '45px', lg: '45px', xl: '45px' }

export default function Plans(props: IProps) {
    const app = useAppBridge()
    const { t } = useTranslation()

    const [loading, setLoading] = useState<boolean>(true)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [isOnboarding, setIsOnboarding] = useState<boolean>(props.isOnboarding)
    const [isReonboarding, setIsReOnboarding] = useState<boolean>(false)
    const [currentPlan, setCurrentPlan] = useState<IPlan | undefined>()
    const [plans, setPlans] = useState<Array<IStaticPlan>>([])
    const [newPlan, setNewPlan] = useState<IStaticPlan | undefined>()
    const [purchaseSuccess, setPurchaseSuccess] = useState<boolean>(false)
    const [purchaseError, setPurchaseError] = useState<boolean>(false)
    const [purchaseSuccessMessage, setPurchaseSuccessMessage] = useState<string | undefined>()
    const [promoCode, setPromoCode] = useState<string | null>()

    useEffect(() => {
        setIsOnboarding(props.isOnboarding)
    }, [props.isOnboarding])

    useEffect(() => {
        const params = new URLSearchParams(new URL(window.location.href).search)
        const chargeId = params.get('charge_id')
        const chargeType = params.get('charge')
        const planId = params.get('plan')
        const code = params.get('c')

        setPlans(getPlans(props.isLegacyStore || !!code, t))
        setIsReOnboarding(props.isLegacyStore ?? false)

        if (code) {
            setPromoCode(code)
        }

        if (chargeId && chargeType && planId) {
            verifyCharge(+chargeId, chargeType)
        } else {
            fetchCurrentPlan()
        }
    }, [])

    /* handles charges within the pricing view */
    async function verifyCharge(chargeId: number, chargeType: string) {
        getSessionToken(app).then(async (sessionToken: string) => {
            const res = await httpRequest<IPurchaseVerificationResponse>({
                method: 'GET',
                url: `/api/shopify/charges?chargeId=${chargeId}&charge=${chargeType}`,
                headers: {
                    Authorization: sessionToken
                }
            }).catch((err) => {
                console.error(err)
                /* no-op */
            })

            if (!res?.verified) {
                setPurchaseError(true)
                setErrorMessage(GET_DEFAULT_ERR_MESSAGE(7))
            } else if (res.verified && !purchaseSuccessMessage) {
                /* the message should only appear on the settings page */
                if (!isOnboarding) {
                    setPurchaseSuccessMessage('Heartbeat plan successfully updated!')
                }

                setPurchaseSuccess(true)
            }

            fetchCurrentPlan()
        })
    }

    async function fetchCurrentPlan(): Promise<void> {
        const plan = await httpRequest<IPlan>({
            method: 'GET',
            url: BASE_URL + '/api/sb/plan',
            headers: {
                Authorization: await getSessionToken(app)
            }
        })

        setCurrentPlan(plan)
        setLoading(false)
    }

    async function initializePurchase(plan: number): Promise<void> {
        setLoading(true)

        const sessionToken = await getSessionToken(app)
        const newPlan = plans.find((entry) => entry.id === plan)

        /* TODO: currently only showing modal when user want's to downgrade */
        if (currentPlan?.id! > newPlan!.id && !newPlan) {
            setNewPlan(newPlan)
            return
        }

        const response = await httpRequest<IPurchaseResponse>({
            method: 'GET',
            url: `/api/purchase?plan=${plan}&isOnboarding=${isOnboarding ? '1' : '0'}${promoCode ? `&promo=${promoCode}` : ''}`,
            headers: {
                Authorization: sessionToken
            }
        })

        if (plan === 1 && response.verified) {
            setNewPlan(undefined)
            fetchCurrentPlan()

            return
        }

        if (response?.url) {
            const url = new URL(response.url)

            adminRedirect(app, url.pathname.slice(6) + url.search)
        }
    }

    return (
        <div>
            {errorMessage && <Toast content={errorMessage} key={Math.random()} error onDismiss={() => setErrorMessage(null)} />}
            {purchaseSuccessMessage && (
                <Toast
                    duration={3}
                    content={purchaseSuccessMessage}
                    onDismiss={() => {
                        setPurchaseSuccessMessage(undefined)
                    }}
                />
            )}
            <Modal
                loading={loading}
                size='large'
                title={t(purchaseSuccess ? 'plans.modal.purchase_success_title' : 'plans.modal.title')}
                onClose={() => {
                    setNewPlan(undefined)
                }}
                primaryAction={{
                    onAction: () => {
                        initializePurchase(newPlan?.id!)
                    },
                    destructive: true,
                    content:
                        currentPlan && newPlan && currentPlan.id! > plans[newPlan!.id - 1].id
                            ? t('plans.modal.downgrade')
                            : t('plans.modal.upgrade')
                }}
                open={!!newPlan && !loading}
            >
                <Layout sectioned>
                    <Layout.Section>
                        {currentPlan && newPlan && currentPlan.id! > plans[newPlan.id - 1].id ? (
                            <h3>
                                {t('plans.modal.confirm_downgrade')} <strong>Heartbeat {plans[newPlan.id - 1].name}? </strong>
                            </h3>
                        ) : (
                            ''
                        )}
                        <br />
                    </Layout.Section>
                </Layout>
            </Modal>

            {isOnboarding && purchaseError && (
                <div>
                    <ErrorAnimation text={t('plans.modal.purchase_error_message')} />
                    <br />
                    <Button
                        fullWidth
                        onClick={() => {
                            window.location.reload()
                        }}
                    >
                        Reload page
                    </Button>
                </div>
            )}

            {isOnboarding && purchaseSuccess && (
                <div>
                    <SuccessAnimation text={t('plans.modal.purchase_success_message')} />
                    <br />
                    <Button
                        fullWidth
                        onClick={() => {
                            setNewPlan(undefined)
                            appRedirect(app, '/')
                        }}
                    >
                        Go to dashboard
                    </Button>
                </div>
            )}

            {(!isOnboarding || (isOnboarding && !purchaseSuccess && !purchaseError)) && (
                <div className={'plans-view' + (isOnboarding ? '-onboarding' : '')}>
                    <Card>
                        {isReonboarding ? (
                            <Text as='h1' variant='headingXl'>
                                {t('plans.re_onboarding_headline')}
                                &nbsp;&nbsp;
                                <Badge size='large' tone='success'>
                                    {t('plans.trial_badge')}
                                </Badge>
                            </Text>
                        ) : isOnboarding ? (
                            <Text as='h1' variant='headingXl'>
                                {t('plans.onboarding_headline')}
                                &nbsp;&nbsp;
                                <Badge size='large' tone='success'>
                                    {t('plans.trial_badge')}
                                </Badge>
                            </Text>
                        ) : (
                            <div>
                                <Text as='h1' variant='headingXl'>
                                    {t('plans.headline')}
                                </Text>
                                <Text as='p' tone='subdued'>
                                    {t('plans.subtext')}
                                </Text>
                            </div>
                        )}

                        <br />

                        <div className='plans'>
                            <Grid>
                                {plans.map((plan) => {
                                    const isCurrent = currentPlan?.id === plan.id

                                    return (
                                        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }} key={'plan-' + plan.id}>
                                            <div className={`plan-box-${plan.id}`}>
                                                <Card>
                                                    <Text as='h2' variant='headingXl' alignment='center'>
                                                        <b>{plan.name}</b>
                                                    </Text>
                                                    <Divider borderColor='transparent' borderWidth={'050'} />

                                                    <Text as='h3'>
                                                        <b>{t('plans.monitors_included')}</b>
                                                    </Text>

                                                    <List type='bullet'>
                                                        {plan.checks.map((check) => {
                                                            return (
                                                                <List.Item key={'check-' + check}>
                                                                    <span className='icon ico-checkmark-green'></span>
                                                                    {check}
                                                                </List.Item>
                                                            )
                                                        })}
                                                    </List>
                                                    <br />
                                                    <Text as='h3'>
                                                        <b>{t('plans.schedule')}</b>
                                                    </Text>

                                                    <List type='bullet'>
                                                        <List.Item>
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: plan.scheduledChecks.value
                                                                }}
                                                            />
                                                        </List.Item>
                                                        <List.Item>
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: plan.performanceHistory.value
                                                                }}
                                                            />
                                                        </List.Item>
                                                    </List>

                                                    <Divider borderColor='transparent' />
                                                    <Button
                                                        onClick={() => {
                                                            if (props.isOnboarding && props.onboardingComplete && plan.price === 0) {
                                                                props.onboardingComplete()
                                                            } else {
                                                                initializePurchase(plan.id)
                                                            }
                                                        }}
                                                        disabled={loading || (!props.isOnboarding && isCurrent)}
                                                        fullWidth
                                                        size='large'
                                                        variant='primary'
                                                    >
                                                        {plan.originalPrice ? t('plans.promo_applied') : ''}
                                                        {plan.originalPrice ? (
                                                            <span>
                                                                <s>{plan.originalPrice}</s>&nbsp;&nbsp;&nbsp;
                                                            </span>
                                                        ) : (
                                                            ((<span></span>) as any)
                                                        )}
                                                        $ {isCurrent ? currentPlan.price : plan.price + ' / month'}{' '}
                                                        {!props.isOnboarding && isCurrent ? (
                                                            <span style={{ color: 'green', fontWeight: 700 }}>(active)</span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </Button>
                                                    {/* {plan.regularChecks.finePrint ? <small>{plan.regularChecks.finePrint}</small> : ''} */}
                                                </Card>
                                            </div>
                                        </Grid.Cell>
                                    )
                                })}
                                {promoCode ? <small>*{t('plans.promo_disclaimer')}</small> : ''}
                            </Grid>
                        </div>
                    </Card>

                    {isOnboarding && (
                        <div className='benefit-container' style={{ backgroundColor: '#efefef', textAlign: 'center' }}>
                            <p>
                                <strong>Not sure which plan to purchase? 🤯</strong>
                            </p>
                            <p>
                                Join the plan most of our heroes are using:&nbsp;
                                <a href='#' className='link'>
                                    Select Basic
                                </a>
                            </p>
                        </div>
                    )}
                    <br />
                    <br />
                    <Divider borderColor='border' />

                    <div className='plans-content-container'>
                        <Layout sectioned>
                            <Layout.Section>
                                <Text as='h2' variant={isOnboarding ? 'headingXl' : 'headingLg'} alignment='center'>
                                    {t('plans.advantages_content.title')}
                                </Text>
                                <br />
                                <Card padding={'1000'}>
                                    <Grid gap={advantageCardGap}>
                                        <Grid.Cell columnSpan={advantageCardColSpans.leftBound as any}>
                                            <img
                                                alt=''
                                                width='100%'
                                                height='200px'
                                                style={{
                                                    objectFit: 'cover',
                                                    objectPosition: 'center'
                                                }}
                                                src={`${BASE_URL}/static/meditate.svg`}
                                            />
                                        </Grid.Cell>
                                        <Grid.Cell columnSpan={advantageCardColSpans.rightBound as any}>
                                            <Text as='h1' variant='headingLg'>
                                                {t('plans.advantages_content.card1.title')}
                                            </Text>
                                            <br />
                                            <Text as='p'>{t('plans.advantages_content.card1.text')}</Text>
                                        </Grid.Cell>
                                    </Grid>
                                </Card>
                                <br />
                                <br />
                                <Card padding={'1000'}>
                                    <Grid gap={advantageCardGap}>
                                        <Grid.Cell columnSpan={advantageCardColSpans.rightBound as any}>
                                            <Text as='h1' variant='headingLg'>
                                                {t('plans.advantages_content.card2.title')}
                                            </Text>
                                            <br />
                                            <Text as='p'>{t('plans.advantages_content.card2.text')}</Text>
                                        </Grid.Cell>
                                        <Grid.Cell columnSpan={advantageCardColSpans.leftBound as any}>
                                            <img
                                                alt=''
                                                width='100%'
                                                height='200px'
                                                style={{
                                                    objectFit: 'cover',
                                                    objectPosition: 'center'
                                                }}
                                                src={`${BASE_URL}/static/to-do-list.svg`}
                                            />
                                        </Grid.Cell>
                                    </Grid>
                                </Card>
                                <br />
                                <br />
                                <Card padding={'1000'}>
                                    <Grid gap={advantageCardGap}>
                                        <Grid.Cell columnSpan={advantageCardColSpans.leftBound as any}>
                                            <img
                                                alt=''
                                                width='100%'
                                                height='200px'
                                                style={{
                                                    objectFit: 'cover',
                                                    objectPosition: 'center'
                                                }}
                                                src={`${BASE_URL}/static/chart.svg`}
                                            />
                                        </Grid.Cell>
                                        <Grid.Cell columnSpan={advantageCardColSpans.rightBound as any}>
                                            <Text as='h1' variant='headingLg'>
                                                {t('plans.advantages_content.card3.title')}
                                            </Text>
                                            <br />
                                            <Text as='p'>{t('plans.advantages_content.card3.text')}</Text>
                                        </Grid.Cell>
                                    </Grid>
                                </Card>
                            </Layout.Section>
                        </Layout>
                    </div>
                </div>
            )}
        </div>
    )
}
