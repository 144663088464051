import axios from 'axios'
import moment from 'moment'
import { useState, useCallback, useEffect } from 'react'
import { Page, Card, DataTable, Select, TableData, Loading, Frame, Button, Modal, Grid, Icon, Text } from '@shopify/polaris'
import { LineChart, DataPoint } from '@shopify/polaris-viz'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAppBridge } from '@shopify/app-bridge-react'
import { getSessionToken } from '@shopify/app-bridge-utils'
import { Annotation } from '@shopify/polaris-viz/build/ts/types'

import { ICheck, IHistoricCheckResult, IStoreEvent, IStoreSettings } from '../../types/supabase'
import { appRedirect } from '../../lib/redirect'
import FooterBanner from '../../components/footer-banner'
import InsightsList from '../../components/insights-list'

type IParams = {
    checkId: string
}

type DataPoints = DataPoint[][]

interface IFormattedData {
    chartData: DataPoints
    tableData: TableData[][]
    details: Object[]
}

function getValueChangeString(previousNumber: number, currentNumber: number): string {
    const difference = currentNumber - previousNumber
    const sign = difference > 0 ? '+' : difference < 0 ? '-' : ''
    const absoluteDifference = Math.abs(difference).toFixed(0)

    return `  ${sign}${absoluteDifference}`
}

function formatAnnotations(events: IStoreEvent[]): any[] {
    const annotations: any[] = []

    for (const event of events) {
        annotations.push({
            label: event.name,
            axis: 'x',
            endKey: moment(event.event_time).format('YYYY/MM/DD'),
            startKey: moment(event.event_time).format('YYYY/MM/DD')
        })
    }

    return annotations
}

function formatData(results: IHistoricCheckResult, timeFormat: string): IFormattedData {
    /* chart data */
    const currentSeries: DataPoint[] = []
    const previousSeries: DataPoint[] = []
    const details: Object[] = []

    /* table data */
    const rows: TableData[][] = []

    if (!results.current || results.current.length === 0) {
        return { chartData: [[], []], tableData: [], details }
    }

    for (let i = 0; i < results.current.length; i++) {
        const key = moment(results.current[i].timestamp).format(`${timeFormat}`)

        currentSeries[i] = {
            key,
            value: results.current[i].result
        }

        const detailsObject = results.current[i].details

        if (typeof detailsObject === 'object' && Object.keys(detailsObject).length !== 0) {
            details.push(...detailsObject!)
        }

        const currentResult = results.current[i].result
        const previousResult = results.current[i - 1]?.result ?? 0
        const absoluteChangePercent =
            previousResult === 0 && currentResult !== 0 ? 100 : +(((currentResult - previousResult) / previousResult) * 100).toFixed(0)

        console.log(currentResult, previousResult)

        rows.push([
            key,
            currentResult,
            getValueChangeString(previousResult, currentResult),
            isNaN(absoluteChangePercent) ? 0 : absoluteChangePercent
        ])

        previousSeries[i] = {
            key,
            value: results.previous[i] ? results.previous[i].result : 0
        }
    }

    return {
        chartData: [currentSeries, previousSeries],
        tableData: rows.reverse(),
        details
    }
}

async function fetchResults(
    token: string,
    checkId: string,
    dateRange?: string
): Promise<[Array<ICheck>, IHistoricCheckResult, Array<IStoreEvent>, IStoreSettings]> {
    const checkResponse = await axios.get<Array<ICheck>>(process.env.REACT_APP_PUBLIC_HOST + '/api/sb/checks?id=' + checkId, {
        headers: {
            Authorization: token
        }
    })

    const resultResponse = await axios.get<IHistoricCheckResult>(
        process.env.REACT_APP_PUBLIC_HOST + '/api/sb/results?checkId=' + checkId + (dateRange ? '&dateRange=' + dateRange : ''),
        {
            headers: {
                Authorization: token
            }
        }
    )

    const eventResponse = await axios.get<IStoreEvent[]>(
        process.env.REACT_APP_PUBLIC_HOST + '/api/sb/events' + (dateRange ? '?dateRange=' + dateRange : ''),
        {
            headers: {
                Authorization: token
            }
        }
    )

    const settingsResponse = await axios.get<IStoreSettings>(process.env.REACT_APP_PUBLIC_HOST + '/api/sb/settings', {
        headers: {
            Authorization: token
        }
    })

    return [checkResponse.data, resultResponse.data, eventResponse.data, settingsResponse.data]
}

const MonitorDetail = () => {
    const app = useAppBridge()
    const { t } = useTranslation()
    const { checkId } = useParams<IParams>()
    const [loading, setLoading] = useState<boolean>(true)
    const [check, setCheck] = useState<ICheck>()
    const [checkResults, setCheckResults] = useState<IFormattedData>({ chartData: [[], []], tableData: [], details: [] })
    const [storeEvents, setStoreEvents] = useState<Annotation[]>([])
    const [dateRange, setDateRange] = useState<string>('30d')
    const handleSelectChange = useCallback((range: string) => setDateRange(range), [])
    const [showDetails, setShowDetails] = useState(false)
    const [insightCount, setInsightCount] = useState<number | undefined>()
    const options = [
        { label: 'Last 7 days', value: '7d' },
        { label: 'Last 14 days', value: '14d' },
        { label: 'Last 30 days', value: '30d' },
        { label: 'Last 12 month', value: '12m' }
    ]

    useEffect(() => {
        if (checkId && !isNaN(+checkId)) {
            getSessionToken(app)
                .then((token: string) => {
                    fetchResults(token, checkId, dateRange)
                        .then((results) => {
                            setCheck(results[0][0])
                            setCheckResults(formatData(results[1], results[3].time_format))
                            setStoreEvents(formatAnnotations(results[2]) as any)
                            setLoading(false)
                        })
                        .catch((err) => {
                            console.error(err)
                        })
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [checkId, app, dateRange])

    return loading ? (
        <Frame>
            <Loading></Loading>
        </Frame>
    ) : (
        <Page
            backAction={{
                content: t('detail.back'),
                onAction: () => {
                    appRedirect(app, '/')
                }
            }}
            title={check?.title}
            subtitle={check?.description}
            primaryAction={false}
            // actionGroups={[
            //     {
            //         title: 'Options',
            //         actions: [
            //             {
            //                 content: 'Disable monitor',
            //                 accessibilityLabel: 'Individual action label',
            //                 onAction: () => alert('Share on Facebook action')
            //             }
            //         ]
            //     }
            // ]}
        >
            <div
                style={{
                    width: '100%',
                    marginBottom: 20
                }}
            >
                <Grid>
                    <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 4, xl: 4 }}>
                        <Select
                            label={t('detail.date_range')}
                            labelInline={true}
                            options={options}
                            onChange={handleSelectChange}
                            value={dateRange}
                        />
                    </Grid.Cell>
                    {checkResults.details?.length !== 0 && (
                        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 4, xl: 4 }}>
                            <Button size='large' onClick={() => setShowDetails(true)}>
                                Show {check?.title!}
                            </Button>
                        </Grid.Cell>
                    )}
                    {insightCount !== undefined && insightCount > 0 && (
                        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 4, xl: 4 }}>
                            <Button size='large' onClick={() => document.getElementById('monitor-detail-insights')?.scrollIntoView()}>
                                {insightCount + ' ' + t('detail.insight_link')}
                            </Button>
                        </Grid.Cell>
                    )}
                </Grid>
            </div>

            <Modal title={'Details'} open={showDetails} onClose={() => setShowDetails(false)}>
                {checkResults.details.map((result) => {
                    return (
                        <div key={Math.random()} style={{ width: '100%', padding: '30px', borderBottom: '1px dotted grey' }}>
                            <pre>{JSON.stringify(result, null, 2)}</pre>
                        </div>
                    )
                })}
            </Modal>

            <Card padding={'500'}>
                <div
                    style={{
                        height: 500
                    }}
                >
                    <LineChart
                        // yAxisOptions={{ labelFormatter: (val: string | number | null) => (val as string) + check?.kpi }}
                        theme='Light'
                        data={[
                            {
                                name: check?.title,
                                data: checkResults.chartData[0],
                                color: 'rgb(0, 161, 159)'
                            },
                            {
                                name: t('detail.last_time_period'),
                                data: checkResults.chartData[1],
                                color: 'rgb(0, 161, 159)',
                                isComparison: true
                            }
                        ]}
                        annotations={storeEvents}
                    />
                </div>
            </Card>

            <br />
            <div id='monitor-detail-insights'>
                <Text as='h2' fontWeight='bold' variant='headingLg'>
                    {t('detail.insight_headline')}
                </Text>
                <br />
                {insightCount === 0 ? (
                    <Text as='p' variant='bodyMd'>
                        {insightCount === 0 && t('detail.insight_none')}
                    </Text>
                ) : (
                    <InsightsList isOnboarding={false} detailCheckId={checkId} insightCountCallback={setInsightCount} />
                )}
            </div>

            <br />
            <Text as='h2' fontWeight='bold' variant='headingLg'>
                {t('detail.historical_change')}
            </Text>
            <br />

            <Card padding={'500'}>
                <DataTable
                    columnContentTypes={['text', 'numeric', 'text', 'numeric']}
                    headings={['Date', `Value (${check?.kpi})`, `Change (${check?.kpi})`, 'Change (%)']}
                    rows={checkResults.tableData}
                    hasZebraStripingOnData
                />
            </Card>

            <div>
                <Frame>{!loading && <FooterBanner />}</Frame>
            </div>
        </Page>
    )
}

export default MonitorDetail
