export enum Plan {
    STARTER = 1,
    BASIC = 2,
    ADVANCED = 3,
    V2_STANDARD = 4,
    V2_PRO = 5
}

export interface IPlan {
    id?: number
    name?: string
    price?: number
    status: boolean
    isLegacyStore?: boolean
    trialActive?: boolean
}

export interface ICheckGroup {
    id: number
    name: string
    rank: number
    parent_id?: number
}

export interface ICheck {
    id: number
    title: string
    smaller_is_better: boolean
    description: string
    slug: string
    status: number
    category: string
    kpi_range: string
    kpi?: number
}

export interface IStore {
    id?: number
    name: string
    myshopify_domain: string
    access_token: string
    domain: string
    email: string
    installed_at?: string
    credits?: number
    score?: number
    last_check?: string
    plan: number
    totalScore?: number
    app_visits?: number
}

export interface IHero {
    domain: string
    name: string
    total_result: string
}

export enum CheckResult {
    ERROR_OR_NOT_CHECKED = -1,
    FAIL = 0,
    NEUTRAL_OPTIONAL = 1,
    SUCCESS = 2
}

export interface ICheckResult {
    id: number
    checks_id: number
    result: CheckResult
    timestamp: number
    details?: Object[]
}

export interface IHistoricCheckResult {
    current: ICheckResult[]
    previous: ICheckResult[]
}

export interface ITotalResult extends IStoreComparison {
    total_result: number
    max_result: number
    total_checks: number
    checks_passed: number
    checks_not_passed: number
    checks_ok: number
    history: ICheckRunEntry[]
}

export enum StoreComparisonHealth {
    WORSE = 0,
    HEALTHIER = 2
}

export enum StoreOverallHealth {
    GREAT = 3,
    GOOD = 2,
    SATISFACTORY = 1,
    BAD = 0
}

export interface IStoreComparison {
    overall_healthiness: StoreOverallHealth
    compared_healthiness: StoreComparisonHealth
    stores_percent_worse: number
    stores_percent_healthier: number
}

export interface ICheckRun {
    id: number
    created_at: string
    store_id: number
    total_result: number
}

export interface ICheckRunEntry {
    id: number
    date: string
    General: number
    Marketing: number
    'Code Quality': number
    SEO: number
    'Social Media': number
}

export interface IStoreEvent {
    id?: number
    name: string
    created_at: string
    event_time: string
    stores_id: number
}

export interface IStoreSettings {
    id?: number
    store_id: number
    monitor_mail: boolean
    marketing_mail: boolean
    monitor_mail_address: string
    time_format: string
    checks_deactivated: string
    primary_locale: string
}

export interface IInsight {
    id: number
    created_at: string
    description: string
    dismissed: boolean
    url?: string
    urlText?: string
    /* clientside used fields */
    large?: boolean
    showSpinner?: boolean
}
