import axios from 'axios'
import { useEffect, useState } from 'react'
import { Page, Layout, Frame, Toast, TabProps, Box, Modal, Banner, Tabs } from '@shopify/polaris'
import { useAppBridge } from '@shopify/app-bridge-react'
import { getSessionToken } from '@shopify/app-bridge-utils'
import { useTranslation } from 'react-i18next'

import FooterBanner from '../../components/footer-banner'
import CardMonitorView from '../../components/card-monitor-list'
import InsightsList from '../../components/insights-list'
import Plans from '../../components/plans'
import { IPlan, IStore, Plan } from '../../types/supabase'
import { IStoreMeta } from '../../types/api'

import './index.css'

async function fetchData(token: string): Promise<[IStore, IPlan, IStoreMeta]> {
    const storeRes = await axios.get<IStore>(process.env.REACT_APP_PUBLIC_HOST + '/api/sb/store', {
        headers: {
            Authorization: token
        }
    })

    const planRes = await axios.get<IPlan>(process.env.REACT_APP_PUBLIC_HOST + '/api/sb/plan', {
        headers: {
            Authorization: token
        }
    })

    const metaRes = await axios.get<IStoreMeta>(process.env.REACT_APP_PUBLIC_HOST + '/api/meta', {
        headers: {
            Authorization: token
        }
    })

    return [storeRes.data, planRes.data, metaRes.data]
}

export default function Index() {
    const app = useAppBridge()
    const params = new URLSearchParams(new URL(window.location.href).search)
    const { t } = useTranslation()

    const [loading, setLoading] = useState<boolean>(true)
    const [showPricingModal, setShowPricingModal] = useState<boolean>(false)
    const [store, setStore] = useState<IStore>()
    const [plan, setPlan] = useState<IPlan>()
    const [storeMeta, setStoreMeta] = useState<IStoreMeta>()
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [tabList, setTabList] = useState<TabProps[]>([])
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0)
    /* When the first check is running, we are pinging the api in intervals to see if we have results */
    const [pingCount, setPingCount] = useState<number | null>(null)

    function createTabList(categories: string[]) {
        setTabList(
            [
                {
                    id: 'tablist-all',
                    content: t('dashboard.buttongroup.all')
                }
            ].concat(
                categories.map((category) => {
                    return {
                        id: 'tablist-' + category,
                        content: t('dashboard.buttongroup.' + category)
                    }
                })
            )
        )

        setLoading(false)
    }

    useEffect(() => {
        setLoading(true)

        getSessionToken(app).then((token: string) => {
            fetchData(token)
                .then(([store, plan, meta]) => {
                    setStore(store)
                    setPlan(plan)
                    setStoreMeta(meta)

                    if (params.get('charge_id') || !plan.status) {
                        setShowPricingModal(true)
                    } else if (store.last_check === null) {
                        /* ping the server again after 10s for results */
                        setTimeout(() => {
                            setPingCount(pingCount ? pingCount + 1 : 1)
                        }, 10_000)
                    }
                })
                .catch((err) => {
                    console.error(err)
                })
        })
    }, [app, pingCount])

    return (
        <Frame>
            {/* {store?.myshopify_domain && <FormbricksComponent store={store.myshopify_domain} />} */}
            <Modal
                size={plan?.status ? 'small' : 'large'}
                open={showPricingModal}
                title={plan?.status ? 'Setup completed ✅' : 'Welcome to Heartbeat!'}
                noScroll={plan?.status}
                onClose={() => {
                    if (!plan?.status) {
                        window.alert('Please select a plan to continue')
                    } else {
                        setShowPricingModal(false)
                    }
                }}
            >
                <Plans isOnboarding={true} isLegacyStore={plan?.isLegacyStore}></Plans>
            </Modal>
            <Page title={t('dashboard.title_default')} fullWidth>
                {errorMessage && <Toast content={errorMessage} error onDismiss={() => setErrorMessage(null)} />}

                <Layout sectioned>
                    <InsightsList isOnboarding={store?.last_check === null} />

                    {!loading && plan?.trialActive && (
                        <div className='trialBanner'>
                            <Banner tone='info'>
                                <b>{`Next check in ${storeMeta?.nextCheckHrs} hours`}</b>.{' '}
                                {plan.id === Plan.V2_STANDARD
                                    ? 'During the test phase, you will receive all the features of the Pro plan. So we will run the check daily instead of weekly. 🎁'
                                    : ''}{' '}
                                Come back tomorrow to check your health monitors 😊
                            </Banner>
                        </div>
                    )}

                    {loading && tabList.length === 0 ? (
                        <div></div>
                    ) : (
                        <Box background={'bg'}>
                            <Tabs
                                tabs={tabList}
                                selected={selectedTabIndex}
                                onSelect={(index) => {
                                    setSelectedTabIndex(index)
                                }}
                            />
                        </Box>
                    )}

                    <CardMonitorView
                        version={pingCount ?? 0}
                        selectedCategoryIndex={selectedTabIndex}
                        onLoadingComplete={(categories: string[]) => {
                            createTabList(categories)
                        }}
                    />
                </Layout>
            </Page>
            <div>
                <Frame>{!loading && <FooterBanner />}</Frame>
            </div>
        </Frame>
    )
}
