import { Grid, Icon, Spinner, Text } from '@shopify/polaris'
import { useEffect, useState } from 'react'
import { getSessionToken } from '@shopify/app-bridge-utils'
import { useAppBridge } from '@shopify/app-bridge-react'
import { useTranslation } from 'react-i18next'
import { CancelMajor } from '@shopify/polaris-icons'
import moment from 'moment'

import { IInsight, IStoreSettings } from '../../types/supabase'
import { httpRequest } from '../../lib/request'
import { BASE_URL } from '../../lib/constants/env'
import { appRedirect } from '../../lib/redirect'

import './style.css'

interface IProps {
    isOnboarding: boolean
    detailCheckId?: string | undefined
    insightCountCallback?: (count: number) => void
}

async function fetchData(token: string, checkId?: string): Promise<[Array<IInsight>, IStoreSettings]> {
    const insights = await httpRequest<Array<IInsight>>({
        method: 'GET',
        url: BASE_URL + '/api/sb/insights' + (checkId ? '?checkId=' + checkId : ''),
        headers: {
            Authorization: token
        }
    })

    const settings = await httpRequest<IStoreSettings>({
        method: 'GET',
        url: BASE_URL + '/api/sb/settings',
        headers: {
            Authorization: token
        }
    })

    return [insights, settings]
}

async function dismiss(token: string, id: number) {
    await httpRequest<Array<IInsight>>({
        method: 'GET',
        url: BASE_URL + '/api/sb/dismiss_insight?id=' + id,
        headers: {
            Authorization: token
        }
    })
}

const InsightsList = (props: IProps): any => {
    const app = useAppBridge()
    const { t } = useTranslation()
    const [list, setList] = useState<Array<IInsight>>([])
    const [storeDateFormat, setStoreDateFormat] = useState<string>('YYYY/MM/DD')
    const [isOnboarding, setIsOnboarding] = useState<boolean | undefined>(undefined)

    useEffect(() => {
        if (isOnboarding === props.isOnboarding) {
            return
        }

        setIsOnboarding(props.isOnboarding)
        getSessionToken(app).then((token: string) => {
            fetchData(token, props.detailCheckId)
                .then((fetchResult) => {
                    const list = fetchResult[0] ?? []
                    const today = moment().toString()

                    if (props.isOnboarding) {
                        list.push(
                            {
                                id: 0,
                                created_at: today,
                                description: t('insights.welcome_1_description'),
                                large: true,
                                showSpinner: true,
                                dismissed: false
                            },
                            {
                                id: 1,
                                created_at: today,
                                description: t('insights.welcome_2_description'),
                                dismissed: false,
                                url: '/settings',
                                urlText: 'goto_settings'
                            }
                        )
                    }

                    if (props.insightCountCallback) {
                        props.insightCountCallback(list.length)
                    }

                    if (!props.detailCheckId && list.length === 0) {
                        list.push({
                            id: 0,
                            created_at: today,
                            description: t('insights.default'),
                            large: true,
                            showSpinner: false,
                            dismissed: false
                        })
                    }

                    setList(list)
                    setStoreDateFormat(fetchResult[1].time_format)
                })
                .catch((err) => {
                    console.error(err)
                })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isOnboarding])

    async function dismissInsight(id: number): Promise<void> {
        await dismiss(await getSessionToken(app), id).then(() => {
            setList(list.filter((element) => element.id !== id))
        })
    }

    return (
        <div className='insights-list'>
            <Grid>
                {list.map((element) => {
                    const bgIndex = isOnboarding ? 1 : [1, 2, 3, 4, 5][~~(5 * Math.random())]

                    return (
                        <Grid.Cell
                            columnSpan={{ xs: 6, sm: 6, md: 3, lg: element.large ? 4 : 3, xl: element.large ? 4 : 3 }}
                            key={'insights-grid-' + element.id}
                        >
                            <div
                                /* select one random background image class out of five */
                                className={`insights-card bounce insights-card-bg-${bgIndex}`}
                            >
                                <div className='insight-card-close' onClick={async () => await dismissInsight(element.id)}>
                                    <Icon source={CancelMajor} tone='subdued' />
                                </div>
                                <div className='insight-card-container'>
                                    <small>{moment(element.created_at).format(storeDateFormat)}</small>
                                    <div dangerouslySetInnerHTML={{ __html: element.description }}></div>
                                    {element.showSpinner && <Spinner size='small' />}

                                    {element.url && !props.detailCheckId && (
                                        <div>
                                            <br />
                                            <a
                                                href=''
                                                className='insight-card-link'
                                                onClick={() => appRedirect(app, element.url as string)}
                                            >
                                                {t(`insights.${element.urlText ?? 'show_details'}`)}&nbsp;→
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Grid.Cell>
                    )
                })}
            </Grid>
        </div>
    )
}

export default InsightsList
