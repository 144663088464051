import axios, { AxiosRequestConfig } from 'axios'

export interface IRequestOptions<T = unknown> {
    method: 'GET' | 'POST' | 'PATCH'
    url: string
    headers?: AxiosRequestConfig['headers']
    payload?: T
}

function getRequest<T>(url: string, headers: IRequestOptions['headers']): Promise<T> {
    return new Promise((resolve, reject) => {
        axios
            .get<T>(url, {
                headers
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch(reject)
    })
}

function postRequest<T, P>(url: string, payload: P, headers: IRequestOptions['headers']): Promise<T> {
    return new Promise((resolve, reject) => {
        axios
            .post<T>(url, payload, {
                headers
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch(reject)
    })
}

export function httpRequest<T = unknown>(options: IRequestOptions<T>): Promise<T> {
    if (options.method === 'GET') {
        return getRequest(options.url, options.headers)
    } else if (options.method === 'POST') {
        return postRequest(options.url, options.payload, options.headers)
    }

    return new Promise((_, reject) => reject({ status: 'method not implemented in httpRequest util' } as T))
}
