import { Icon } from '@shopify/polaris'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ChecklistMajor, NotificationMajor, SettingsMajor, PlanMajor } from '@shopify/polaris-icons'

import './style.css'

interface IProps {
    initialNav: string
    onSetNav: (nav: string) => void
}

const SideNavigation = (props: IProps) => {
    const { t } = useTranslation()
    const [nav, setNav] = useState<string>(props.initialNav)

    useEffect(() => {
        props.onSetNav(nav)
    }, [nav, props])

    return (
        <div className='side-nav'>
            <div className={'side-nav-item' + (nav === 'general' ? ' side-nav-item-active' : '')} onClick={() => setNav('general')}>
                <Icon source={SettingsMajor} tone='subdued' /> {t('settings.nav.general')}
            </div>
            <div
                className={'side-nav-item' + (nav === 'notifications' ? ' side-nav-item-active' : '')}
                onClick={() => setNav('notifications')}
            >
                <Icon source={NotificationMajor} tone='subdued' />
                {t('settings.nav.notifications')}
            </div>
            <div className={'side-nav-item' + (nav === 'monitors' ? ' side-nav-item-active' : '')} onClick={() => setNav('monitors')}>
                <Icon source={ChecklistMajor} tone='subdued' />
                {t('settings.nav.monitors')}
            </div>
            <div className={'side-nav-item' + (nav === 'plans' ? ' side-nav-item-active' : '')} onClick={() => setNav('plans')}>
                <Icon source={PlanMajor} tone='subdued' />
                {t('settings.nav.plan')}
            </div>
        </div>
    )
}

export default SideNavigation
