import { Banner, Layout } from '@shopify/polaris'

import './index.css'

const FooterBanner: React.FC = () => {
    return (
        <div className='footer-container'>
            <div className='footer-banner'>
                <Layout.Section>
                    <Banner>
                        Heartbeat by&nbsp;
                        <a className='link' target='_blank' href='https://apps.shopify.com/partners/bitwald-ug-haftungsbeschrankt'>
                            Bitwald
                        </a>
                        . Made in Germany 🇩🇪. Please leave a{' '}
                        <a
                            className='link'
                            target='_blank'
                            href='https://apps.shopify.com/shopcheck-xxl?locale=de#modal-show=ReviewListingModal'
                        >
                            review
                        </a>
                        !
                    </Banner>
                </Layout.Section>
            </div>
        </div>
    )
}

export default FooterBanner
