import { useEffect, useState } from 'react'
import { getSessionToken } from '@shopify/app-bridge-utils'
import { useAppBridge } from '@shopify/app-bridge-react'
import { Grid } from '@shopify/polaris'
import { DataSeries } from '@shopify/polaris-viz'

import CardMonitor, { Trend } from '../card-monitor'
import { ICheck, ICheckResult, IHistoricCheckResult } from '../../types/supabase'
import { httpRequest } from '../../lib/request'
import { BASE_URL } from '../../lib/constants/env'

import './style.css'

interface IProps {
    selectedCategoryIndex?: number
    onLoadingComplete: (categories: string[]) => void
    version: number
}

interface IIntermediateCheck extends ICheck {
    data: DataSeries[]
    previousResult?: number
    currentResult?: number
    percentageChange?: number
    numericTrend?: Trend
    evaluatedTrend?: Trend
}

/* change: checkId */
interface IOrderedResultList {
    [change: number]: number
}

type HistoricResultsByCheckId = Map<number, DataSeries[]>

async function fetchData(token: string): Promise<[string[], ICheck[], IHistoricCheckResult]> {
    const [categoryResponse, checkResponse, resultResponse] = await Promise.all([
        httpRequest<Array<string>>({
            method: 'GET',
            url: BASE_URL + '/api/sb/categories',
            headers: {
                Authorization: token
            }
        }),
        httpRequest<Array<ICheck>>({
            method: 'GET',
            url: BASE_URL + '/api/sb/checks',
            headers: {
                Authorization: token
            }
        }),
        httpRequest<IHistoricCheckResult>({
            method: 'GET',
            url: BASE_URL + '/api/sb/results',
            headers: {
                Authorization: token
            }
        })
    ])

    return [categoryResponse, checkResponse, resultResponse]
}

function formatData(checkIds: number[], results: IHistoricCheckResult): HistoricResultsByCheckId {
    const resultMap: HistoricResultsByCheckId = new Map()

    for (const id of checkIds) {
        const series: DataSeries[] = []

        series[0] = { data: [], isComparison: true }
        series[1] = { data: [] }

        const current = results.current.filter((r) => r.checks_id === id)
        const previous = results.previous.filter((r) => r.checks_id === id)

        for (let i = 0; i < current.length; i++) {
            if (previous[i]) {
                series[0].data.push({
                    key: 'result-prev-' + previous[i].id,
                    value: previous[i].result
                })
            }

            series[1].data.push({
                key: 'result-' + current[i].id,
                value: current[i].result
            })
        }

        resultMap.set(id, series)
    }

    return resultMap
}

function calculatePercentageChange(currentResult: number | null, lastResult: number): number {
    if (lastResult === 0 && currentResult !== 0) {
        return 100
    } else if (!currentResult || currentResult === lastResult) {
        return 0
    }

    return +(((currentResult - lastResult) / lastResult) * 100).toFixed(0)
}

const CardMonitorView = (props: IProps): any => {
    const app = useAppBridge()
    const [checks, setChecks] = useState<ICheck[]>([])
    const [filteredChecks, setFilteredChecks] = useState<ICheck[] | IIntermediateCheck[]>([])
    const [results, setResults] = useState<HistoricResultsByCheckId>()
    const [categories, setCategories] = useState<string[]>([])
    const orderedList: IOrderedResultList = {}

    useEffect(() => {
        getSessionToken(app).then((token: string) => {
            fetchData(token)
                .then(([categories, checks, results]) => {
                    if (Array.isArray(checks)) {
                        props.onLoadingComplete(categories)
                        setCategories(categories)
                        setChecks(checks)
                        setFilteredChecks(checks)
                        setResults(
                            formatData(
                                checks.map((check) => check.id),
                                results
                            )
                        )
                    }
                })
                .catch((err) => {
                    console.error(err)
                })
        })
    }, [props.version])

    useEffect(() => {
        const categoryFilter = categories && props.selectedCategoryIndex ? categories[props.selectedCategoryIndex - 1] : undefined
        let filteredCheckList: ICheck[] = []

        if (categoryFilter) {
            filteredCheckList = checks.filter((check) => check.category === categoryFilter) as IIntermediateCheck[]
        } else {
            filteredCheckList = checks as IIntermediateCheck[]
        }

        const intermediateCheckList: IIntermediateCheck[] = []

        for (const check of filteredCheckList) {
            const checkResults = results?.get(check.id)

            if (!checkResults) {
                continue
            }

            const currentCycleResults = checkResults[1]?.data
            const previousResult = currentCycleResults[currentCycleResults.length - 2]?.value ?? 0
            const currentResult = currentCycleResults[currentCycleResults.length - 1]?.value ?? 0
            const percentageChange = calculatePercentageChange(currentResult, previousResult)
            const numericTrend = currentResult === previousResult ? 'neutral' : currentResult > previousResult ? 'positive' : 'negative'
            let evaluatedTrend: Trend = 'neutral'

            if (numericTrend === 'neutral') {
                evaluatedTrend = 'neutral'
            } else {
                evaluatedTrend =
                    (check.smaller_is_better && numericTrend === 'negative') || (!check.smaller_is_better && numericTrend === 'positive')
                        ? 'positive'
                        : 'negative'
            }

            intermediateCheckList.push({
                ...check,
                data: checkResults,
                currentResult,
                previousResult,
                percentageChange,
                numericTrend,
                evaluatedTrend
            })
        }

        setFilteredChecks(intermediateCheckList.sort((a, b) => Math.abs(b.percentageChange!) - Math.abs(a.percentageChange!)))
    }, [props.selectedCategoryIndex, results])

    return (
        <div className='card-monitor-view'>
            <Grid>
                {filteredChecks
                    .map((check) => {
                        return (
                            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 4, xl: 3 }} key={'grid-' + check.id + Math.random()}>
                                <CardMonitor
                                    id={check.id}
                                    title={check.title}
                                    description={check.description}
                                    kpi={check.kpi}
                                    kpi_range={check.kpi_range}
                                    data={(check as IIntermediateCheck).data}
                                    currentResult={(check as IIntermediateCheck).currentResult!}
                                    percentageChange={(check as IIntermediateCheck).percentageChange!}
                                    evaluatedTrend={(check as IIntermediateCheck).evaluatedTrend!}
                                    numericTrend={(check as IIntermediateCheck).numericTrend!}
                                ></CardMonitor>
                            </Grid.Cell>
                        )
                    })
                    .filter(Boolean)}
            </Grid>
        </div>
    )
}

export default CardMonitorView
