import { IStaticPlan } from '../types/base'

export default function getPlans(isPromoted = false, translate: any): IStaticPlan[] {
    return [
        {
            id: 4,
            active: true,
            name: 'Basic',
            checks: ['Product inventory', 'Spell checks', 'Shopify setup', 'Page speed', 'SEO'],
            scheduledChecks: {
                value: translate('plans.weekly'),
                iconClass: 'ico-cross-false',
                incentive: translate('plans.weekly_incentive')
            },
            performanceHistory: {
                value: translate('plans.six_months'),
                iconClass: 'ico-checkmark-green',
                incentive: ''
            },
            price: isPromoted ? 12.99 : 14.99,
            originalPrice: isPromoted ? 14.99 : null,
            hasBadge: false
        },
        {
            id: 5,
            active: true,
            name: 'Pro',
            checks: ['Product inventory', 'Spell checks', 'Shopify setup', 'Page speed', 'SEO'],
            scheduledChecks: {
                value: translate('plans.daily'),
                iconClass: 'ico-checkmark-green',
                incentive: translate('plans.daily_incentive')
            },
            performanceHistory: {
                value: translate('plans.twelve_months'),
                iconClass: 'ico-checkmark-green',
                incentive: ''
            },
            price: isPromoted ? 22.99 : 24.99,
            originalPrice: isPromoted ? 24.99 : null,
            hasBadge: true
        }
    ]
}
